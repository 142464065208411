/**
 * Set of functions that manipulate with API calls from:
 *   - v1/webinars.json
 *   - v1/webinars/{id}.json
 */
import { isNumber } from '../../globalUtils';
import { genericItemToMediaCard } from './apiMediaItems';
import { resourceToMediaCard } from './apiResources';

/** this constant exist, because the access to the medable_type is only in the
 * media_items.json call, however this constant is refered also in other context:
 */
const WEBINAR_MEDIABLE_TYPE = 'Webinar';

/**
 * Accept a webinar object and convert to object expected by MediaCard vmTemplate.
 * @param {Object} webinarItem DB entity of a single webinar
 */
function webinarToMediaCard(webinarItem = {}, options = {}) {
  const { defaultImage, themeColor, uiTitle } = options[WEBINAR_MEDIABLE_TYPE] || options;
  const { contentsessions = [] } = webinarItem;
  return {
    ...webinarItem,
    ...genericItemToMediaCard(webinarItem),
    href: `/webinars/${webinarItem.id}`,
    mediableTypeUiTitle: uiTitle,
    mediableTypeColor: themeColor,
    image: webinarItem.preview_url ?? defaultImage,
    chairs: contentsessions[0]?.chairs,
    tags: webinarItem?.tags?.map((el) => el?.tag).join(', '),
    // Note on adding speakers: speakers are burried under the path: contentsessoions.resourcegroups.resources.authors
    // flow is as follows: 1. itterate over resources, 2. if resource type is 'Content' bring authors to the beginning
    // otherwise to the end, 3. deduplicate authors which already appeared in previouse resources.
    speakers: contentsessions
      .reduce(
        (acc, el) => el?.resourcegroups?.reduce(
          (acc2, el2) => el2?.body?.resources?.reduce(
            (acc3, el3) => (el3.type === 'Content' // 2. add authors from resource type 'Content' first
              ? [...el3.authors, ...acc3]
              : [...acc3, ...el3.authors]),
            acc2
          ),
          acc
        ),
        []
      )
      .filter(
        // 3. this removes duplicated authors
        (el, idx, self) => self.findIndex((el2) => el2.id === el.id) === idx
      ),
    vmWebinarResources: contentsessions
      .reduce(
        (acc1, el1) => el1.resourcegroups.reduce(
          (acc2, el2) => el2.body.resources.reduce(
            (acc3, el3) => [...acc3, resourceToMediaCard(el3, options)],
            acc2
          ),
          acc1
        ),
        []
      )
      .filter((el) => el.vmIfShow)
      .sort((a, b) => a.pos_in_session - b.pos_in_session),
  };
}

function webinarsToMediaCard(webinars = [], options = {}) {
  return webinars
    .filter((el) => isNumber(el.id)) // this rejects elements with id === null or undefined
    .map((el) => webinarToMediaCard(el, options));
}

/**
 * Find first session recording (contentsession.recording_file_path) and return if exists:
 * @param {Object} webinarItem
 * @returns {string} First found path in contentsessions
 */
function getRecordingFilePath(webinarItem = {}) {
  const { contentsessions = [] } = webinarItem;
  return contentsessions.find((el) => el.recording_file_path)?.recording_file_path;
}
/**
 * Find first session recording (contentsession.video_file_path) and return if exists:
 * @param {Object} webinarItem
 * @returns {string} First found path in contentsessions
 */
function getVideoFilePath(webinarItem = {}) {
  const { contentsessions = [] } = webinarItem;
  return contentsessions.find((el) => el.video_file_path)?.video_file_path;
}

function getMediaPlayerProps(webinarItem = {}, options = {}) {
  const { access_for_user: accessForUser, vmWebinarResources = [] } = webinarItem;
  const { webinarMediaPlayerFolder2Show } = options;
  if (accessForUser) {
    // Check ticket https://jmarquardt.atlassian.net/browse/DB-1634 for logic to find folder2Show and directLink.
    // Check if we play something from clienData after click on resource:
    if (webinarMediaPlayerFolder2Show) {
      return {
        folder2Show: webinarMediaPlayerFolder2Show,
        ifUsePlayer: true,
        playerSettings: { exporttype: 'autoFolder' },
      };
    }
    // Check if there is recording:
    const directLink = getRecordingFilePath(webinarItem);
    if (directLink) {
      return {
        directLink,
        ifUsePlayer: true,
        playerSettings: { exporttype: 'direct', mediatype: 'video/mp4' },
      };
    }
    // Find first playable resource:
    const folder2Show = vmWebinarResources[0]?.path;
    if (folder2Show) {
      return {
        folder2Show,
        ifUsePlayer: true,
        playerSettings: { exporttype: 'autoFolder' },
      };
    }
    return { ifUsePlayer: false };
  }

  return {
    folder2Show: undefined,
    directLink: getVideoFilePath(webinarItem),
    ifUsePlayer: false,
    playerSettings: {},
  };
}

function getPosterFilePath(webinarItem = {}) {
  const { contentsessions = [] } = webinarItem;
  return contentsessions.find((el) => el.poster_file_path)?.poster_file_path;
}

const getNumberOfSpeakers = (data) => data?.contentsessions?.[0]?.resourcegroups?.flatMap((el) => el?.body?.resources?.[0]?.authors).length;

const postProcessApiResponse = () => (res) => ({
  ...res,
  data: {
    ...res.data,
    vmPostProcess: {
      numOfSpeakers: getNumberOfSpeakers(res.data)
    }
  }
});

export {
  webinarToMediaCard,
  webinarsToMediaCard,
  WEBINAR_MEDIABLE_TYPE,
  getMediaPlayerProps,
  getPosterFilePath,
  postProcessApiResponse,
  getVideoFilePath,
  getRecordingFilePath
};
