// eslint-disable-next-line import/prefer-default-export
export const SET_NETWORKING_WIDGET = 'modules/networking-widget/set';

export const setNetworkingWidget = (payload) => ({
  type: SET_NETWORKING_WIDGET,
  payload
});

const selectNetworkingWidget = (state) => state.appState.networkingWidget;

export const selectStatus = (state) => selectNetworkingWidget(state)?.status;

export const setStatus = (status) => () => (dispatch, getState) => {
  const stateNew = {
    ...selectNetworkingWidget(getState()),
    status
  };

  dispatch(setNetworkingWidget(stateNew));
};

export const setLoading = setStatus('loading');
export const setReady = setStatus('ready');
export const setError = setStatus('error');

export const setUnreadMessageCount = (count) => (dispatch, getState) => {
  const stateNew = {
    ...selectNetworkingWidget(getState()),
    unreadMessageCount: count // https://jmarquardt.atlassian.net/browse/VSX-572
  };

  dispatch(setNetworkingWidget(stateNew));
};
export const selectUnreadMessageCount = (state) => selectNetworkingWidget(state)?.unreadMessageCount;
