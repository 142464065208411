import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useApiDataHook } from '../../global-hooks';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';
import {
  apiDataInfoPropType,
  componentsPropType,
  stylesPropType,
  vmPropTypes
} from '../../global-prop-types';
import { Loading } from '../global';

// Intentionally do not change the component name so in the feature we can replce InfinitScroll with this version
const InfiniteScrollUrlSearchParams = (props) => {
  const {
    apiDataInfo,
    components,
    infiniteScrollApiData,
    styles,
    variant,
    urlParams,
    urlSearchParams,
    textXSingleResult,
    textXResults,
    textNoResults,
    noResultsComponents,
    ...restProps
  } = props;

  const boxRef = useRef(null);
  const [allData, setAllData] = useState([]);
  const [nextPage, setNextPage] = useState(null);

  // This component responds to all the API data fields:
  const {
    data = [],
    headers = {},
    isReady,
    isLoading,
    timestamp
  } = useApiDataHook(
    apiDataInfo.map((el) => ({
      ...el,
      body: { ...el.body, page: nextPage ?? '1' }
    })),
    urlParams,
    urlSearchParams,
    { dataBank: { props: restProps } }
  )[infiniteScrollApiData] ?? {};

  const {
    'x-total': xTotal,
    'x-page': xPage,
    'x-next-page': xNextPage
  } = headers;

  // the nextPage state tells the difference if we call the component with different
  // search parameters (first page of the search result) or if we call the component
  // with the same search parameters but incremented body.page prop.
  // IMPORTANT:
  useEffect(() => {
    setNextPage(null);
  }, [JSON.stringify(urlSearchParams)]);

  useEffect(() => {
    setAllData((x) => (xPage === '1' ? data : [...x, ...data]));
  }, [timestamp]);

  useEffect(() => {
    const isAtTheBottom = () => {
      if (
        Math.floor(boxRef?.current?.getBoundingClientRect().bottom)
          <= Math.ceil(window.innerHeight)
        && xNextPage
        && !isLoading
      ) {
        setNextPage(xNextPage);
      }
    };
    window.addEventListener('scroll', isAtTheBottom);
    return () => {
      window.removeEventListener('scroll', isAtTheBottom);
    };
  }, [xNextPage, isLoading]);

  if (!allData.length && noResultsComponents?.length) {
    return (
      <ComponentsArrRenderer components={noResultsComponents} {...restProps} />
    );
  }
  if (!allData.length) {
    return (
      <div className={variant?.header} style={styles?.header}>
        {textNoResults}
      </div>
    );
  }
  return (
    <div className={variant?.wrapper} style={styles?.wrapper} ref={boxRef}>
      {xTotal && (
        <div className={variant?.header} style={styles?.header}>
          {`${xTotal} ${xTotal === '1' ? textXSingleResult : textXResults}`}
        </div>
      )}
      <ComponentsArrRenderer
        components={components}
        {...restProps}
        infiniteScrollData={allData}
      />
      {!isReady && <Loading />}
    </div>
  );
};

InfiniteScrollUrlSearchParams.propTypes = {
  variant: PropTypes.shape({
    wrapper: PropTypes.string,
    header: PropTypes.string
  }),
  styles: stylesPropType,
  components: componentsPropType,
  apiDataInfo: apiDataInfoPropType,
  infiniteScrollApiData: PropTypes.string,
  urlParams: PropTypes.shape({}),
  urlSearchParams: PropTypes.shape({}),
  textXSingleResult: PropTypes.string,
  textXResults: PropTypes.string,
  textNoResults: PropTypes.string,
  noResultsComponents: componentsPropType
};

InfiniteScrollUrlSearchParams.defaultProps = {
  textXSingleResult: 'Result',
  textXResults: 'Results',
  textNoResults: 'No results for this search.'
};

InfiniteScrollUrlSearchParams.vmPropTypes = {
  components: vmPropTypes.components,
  noResultsComponents: vmPropTypes.components,
  variant: {
    wrapper: vmPropTypes.string,
    header: vmPropTypes.string
  },
  apiDataInfo: vmPropTypes.array,
  infiniteScrollApiData: vmPropTypes.string,
  textXSingleResult: vmPropTypes.string,
  textXResults: vmPropTypes.string,
  textNoResults: vmPropTypes.string
};

InfiniteScrollUrlSearchParams.processPropVmTypes = (props, options = {}) => {
  const {
    apiDataInfo,
    components,
    infiniteScrollApiData,
    styles,
    variant,
    urlParams,
    urlSearchParams,
    textXSingleResult,
    textXResults,
    textNoResults,
    noResultsComponents,
    ...restProps
  } = props;
  // This function if provided will get array element vmType from API call models available on the admin side
  const { apiDataInfoItemToVmType } = options;

  return ComponentsArrRenderer.processPropVmTypes({
    ...restProps,
    infiniteScrollData: apiDataInfo?.length
      ? apiDataInfoItemToVmType(apiDataInfo[0])
      : vmPropTypes.array
  });
};

export default InfiniteScrollUrlSearchParams;
