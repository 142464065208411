import { lazy } from 'react';

export const NetworkingWidgetInit = lazy(() => import('./ui/Init'));
export const NetworkingWidgetShow = lazy(() => import('./ui/Show'));
export const NetworkingWidgetSettings = lazy(() => import('./ui/Settings'));
export const NetworkingWidgetNotificationCount = lazy(() => import('./ui/NotificationCount'));

export {
  SET_NETWORKING_WIDGET,
  setLoading,
  setReady,
  setError,
  selectStatus
} from './model/slice';
