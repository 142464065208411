/* Collection of type definition for components properties

  CONVENTION:
    The value of a key it the UPPER_CASE string
    of the key with prefix `VM_PROPTYPE_`.

  Example:
    arrayOfStrings: VM_PROPTYPE_ARRAY_OF_STRINGS */

import { isObj, isStr } from '../../global-utils/globalUtils';

const vmTypesSingle = {
  any: 'VM_TYPE_ANY',

  // primitive types:
  string: 'string',
  boolean: 'boolean',
  number: 'number',
  float: 'float',
  object: 'object',
  data: 'data',
  array: 'array',

  // common component props:
  components: 'components',
  arrayWithComponents: 'arrayWithComponents',
  styles: 'styles',

  // properties that need custom editors:
  icon: 'icon',
  htmlText: 'htmlText',
  color: 'color',
  image: 'image',
  mediaUpload: 'VM_PROPTYPE_MEDIA_UPLOAD',
  fileuploads: 'VM_PROPTYPE_FILEUPLOADS',
  // // Types for models: the value for the model has to be a key in adminModels!
  // modelCourses: 'courses',
  // modelCategories: 'categories',
  // modelGuidelines: 'guidelines',
  // modelNews: 'news',
  // modelSponsors: 'sponsors',
  // modelSponsorsGroups: 'sponsorsGroups',
  // modelCourseCategories: 'coursecategories',
  // modelTopics: 'topics',
  // modelWebinar: 'webinar',
  // modelArticleType: 'articleType',
  // modelArticleCategory: 'articleCategory',
  // modelArticle: 'article',
  // modelTag: 'tag',
  // modelMeetingsCategories: 'meetingsCategories',
  // modelMeetings: 'meetings',

  // others:
  apiData: 'VM_PROPTYPE_API_DATA',
  apiContentRequest: 'VM_PROPTYPE_API_CONTENT_REQUEST',
  url: 'url',
  internalHref: 'internalHref',
  timestamp: 'date', // TODO: Change to VM_PROPTYPE_TIMESTAMP
  hideWhen: 'VM_PROPTYPE_HIDE_WHEN',
  columnSizesPerBreakpoint: 'columnSizesPerBreakpoint',
  className: 'VM_PROPTYPE_CLASS_NAME',
  timezone: 'VM_TYPE_TIMEZONE',
  locale: 'VM_TYPE_LOCALE',
  version: 'VM_TYPE_VERSION',

  // type to set vmType in editors:
  vmTypeSelect: 'VM_PROPTYPE_VM_TYPE_SELECT',
  dataMapping: 'VM_TYPE_DATA_MAPPING',
  dataMappingEvent: 'VM_TYPE_DATA_MAPPING_EVENT',
  mapArrayElements: 'VM_TYPE_MAP_ARRAY_ELEMENTS',
  postProcessApiResponse: 'VM_TYPE_POST_PROCESS_API_RESPONSE',
  flatMap: 'VM_TYPE_FLAT_MAP',
  propToPropMap: 'VM_TYPE_PROP_TO_PROP_MAP',

  // component spacific types:
  collapsable: 'collapsable',
  prosCons: 'prosCons',
  faq: 'faq',
  function: 'function',
  address: 'address',
  selectAPIArray: 'VM_TYPE_SELECT_API_ARRAY',
  slugResetter: 'slugResetter',
  recommendedMediaItemList: 'VM_TYPE_RECOMMENDED_MEDIA_ITEM_LIST'
};

export const getArrayVmTypeOf = (vmType) => ({
  vmType: vmTypesSingle.array,
  arrayItemVmType: vmType
});

export const vmTypes = {
  ...vmTypesSingle,
};

export const getVmTypeByKey = (vmTypesObj) => {
  // vmTypesObj is an object or a string representgin a key
  // from vmTypes.
  if (isStr(vmTypesObj)) return vmTypes[vmTypesObj];
  if (isObj(vmTypesObj)) {
    return Object.keys(vmTypesObj).reduce(
      (acc, key) => ({
        ...acc,
        [key]: getVmTypeByKey(vmTypesObj[key])
      }),
      {}
    );
  }
  return undefined;
};

export const isVmType1CompatibleWithVmType2 = (vmType1, vmType2) => {
  // Check if one vmType is compatible with the other, eg. string can accept in
  // but int cannot accept string
  let ret = false;
  if (vmType2 === vmType1) ret = true;

  if (vmType2 === vmTypesSingle.array) {
    ret = vmType1.type === vmTypesSingle.array;
  }

  return ret;
};

// Checks if the variable is one of the defined vmTypes
export const isVmType = (obj) => Object.values(vmTypes).some(
  (vmType) => vmType === obj || vmType === obj?.vmType
);
export const vmTypeToKey = (vmType) => Object.keys(vmTypes).find((key) => vmTypes[key] === vmType) ?? 'unknown';

/** vmType can be the value it's self or if it is an object, it has a property vmType */
export const getVmType = (obj) => (isVmType(obj) ? obj?.vmType ?? obj : undefined);
